<template>
  <div clas="totalWrapper" id="deadline">
    <div class="greyOverlay" v-show="this.deadline.countDown <= 0"></div>
    <div class="wrapper">
      <span
        :class="['courierLogo ' + courier + ' ' + fullscreen]"
        style="position: relative"
      />
      <div :class="['deadlineTime ' + fullscreen]">
        <div>{{ deadlineHour }}:{{ deadlineMinute }}</div>
        <svg
          class="deadlineTime clock"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
          />
        </svg>
      </div>
      <div :class="['countDownWrapper ' + fullscreen]">
        <div :class="['countDownContainer ' + fullscreen]">
          <div :class="['countDownHours ' + fullscreen]">
            {{ countDownHours }}
          </div>
          <div :class="['countDownText ' + fullscreen]">Hour</div>
        </div>
        <div :class="['countDownSeperator ' + fullscreen]"></div>
        <div :class="['countDownContainer ' + fullscreen]">
          <div :class="['countDownMinutes ' + fullscreen]">
            {{ countDownMinutes }}
          </div>
          <div :class="['countDownText ' + fullscreen]">Mins</div>
        </div>
      </div>
      <div
        :class="['total_wrapper border ' + fullscreen]"
        v-if="totalOrders >= 0 && this.deadline.countDown > 1800000"
      >
        <div class="wrapper">
          <div :class="['orderCount ' + fullscreen]">{{ totalOrders }}</div>
          <div :class="['orderText ' + fullscreen]">left</div>
        </div>
      </div>
      <div :class="['borderPulse ' + fullscreen]" v-else>
        <div class="wrapper">
          <div :class="['orderCountPulse ' + fullscreen]">
            {{ totalOrders }}
          </div>
          <div :class="['orderText ' + fullscreen]">left</div>
        </div>
      </div>
      <div :class="['orders ' + fullscreen]">
        New: {{ deadline.orders.new }}
      </div>
      <div :class="['orders ' + fullscreen]">
        Pick: {{ deadline.orders.pick }}
      </div>
      <div :class="['orders ' + fullscreen]">
        Pack: {{ deadline.orders.pack }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardDeadlineShipping',
  props: {
    deadline: {},
    courier: String,
    fullscreen: String,
  },
  data() {
    return {
      show: {
        pickOrder: 0,
        packOrder: 0,
        newOrder: 0,
      },
    };
  },
  computed: {
    totalOrders() {
      return (
        this.deadline.orders.new +
        this.deadline.orders.pick +
        this.deadline.orders.pack
      );
    },
    deadlineMinute() {
      return this.deadline.deadlines.nextDeadlineMinute.toLocaleString(
        'en-US',
        { minimumIntegerDigits: 2, useGrouping: false }
      );
    },
    deadlineHour() {
      return this.deadline.deadlines.nextDeadlineHour.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    countDownMinutes() {
      return Math.floor(
        (this.deadline.countDown / 1000 / 60) % 60
      ).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
    countDownHours() {
      return Math.floor(
        (this.deadline.countDown / 1000 / 60 / 60) % 24
      ).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/style/variables/icons';

.totalWrapper {
  margin-top: 20px;
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
}
.courierLogo {
  left: 0;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 25px;
  &.burd {
    background-image: $burd;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.shop {
    background-image: $shop;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.gls {
    background-image: $gls;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.pdk {
    background-image: $pdk;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.dao {
    background-image: $dao;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.instabox {
    background-image: $instabox;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.dhl {
    background-image: $dhl;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
  &.bring {
    background-image: $bring;

    &.true {
      margin-top: 30px;
      width: 150px;
      height: 150px;
    }
  }
}
.deadlineTime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  height: 100%;
  color: black;
  text-align: center;
  font-size: 1.6vw;
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  &.true {
    font-size: 2.478rem;
  }
  &.clock {
    width: 12%;
    margin-left: 5px;
  }
}
.countDownWrapper {
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;
}
.countDownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  padding: 3px;
}
.countDownText {
  font-size: 1.222rem;
  color: black;
  &.true {
    font-size: 1.622rem;
  }
}
.countDownHours {
  font-size: 2vw;
  color: black;
  &.true {
    font-size: 2.4vw;
  }
}
.countDownMinutes {
  font-size: 2vw;
  color: black;
  &.true {
    font-size: 2.4vw;
  }
}
.countDownSeperator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #eaeaea;
  margin: 5px;
  &.true {
    border: 1px solid #1b577a;
  }
}
.orderCount {
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  font-size: 1.622rem;
  color: #1b577a;
  display: flex;
  margin: auto;
  &.true {
    font-size: 2.478rem;
  }
}
.orders {
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  font-size: 1.222rem;
  color: #1b577a;
  display: flex;
  justify-content: center;
  min-width: max-content;
  &.true {
    font-size: 1.622rem;
  }
}
.orderText {
  font-size: 0.7778rem;
  margin-top: -7px;
  &.true {
    font-size: 0.8889rem;
  }
}
.orderCountPulse {
  font-weight: bold;
  font-family: 'Open sans', sans-serif;
  font-size: 1.222rem;
  color: #ff686e;
  display: flex;
  justify-content: center;
  margin: auto;
  min-width: max-content;
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  &.true {
    font-size: 1.622rem;
  }
}

.border {
  display: flex;
  border: solid;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  color: #1b577a;
  &.true {
    height: 120px;
    width: 120px;
  }
}
.borderPulse {
  display: flex;
  border: solid;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  color: #ff686e;
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  &.true {
    height: 120px;
    width: 120px;
  }
}
</style>
