<template>
  <div>
    <div class="dashviewcol">
      <div
        :class="['total-wrapper ' + fullscreen]"
        @keydown.esc="ToggleFullscreen()"
      >
        <div :class="['dashboard-actions ' + fullscreen]">
          <select class="companyDropDown" v-model="companyId">
            <option
              v-for="company in companyList"
              :key="company.id"
              :value="company.id"
            >
              {{ company.name }}
            </option>
          </select>
          <div :class="['clock ' + fullscreen]">{{ formatClock }}</div>
        </div>
        <div class="deadlinesWrapper">
          <div
            class="total-wrapper__item"
            v-for="deadline in VMArray"
            :key="deadline.courierCompany"
          >
            <DashbordDeadlineShipping
              :courier="deadline.courierCompany"
              :deadline="deadline"
              :fullscreen="fullscreen"
            ></DashbordDeadlineShipping>
          </div>
        </div>
      </div>
      <div class="fullscreen">
        <div
          :class="['fullscreenButton ', fullscreen]"
          @click="ToggleFullscreen()"
        ></div>
      </div>
      <input v-if="fullscreen" ref="esc" @keydown.esc="ToggleFullscreen()" />
    </div>
    <!--         <div class="dash view" style="margin-top: 10px;">
            <DashboardDeadlineList />
        </div> -->
    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { BPA } from '@/helpers/BPA';
import DashbordDeadlineShipping from '@/components/blocks/Dashboard/DashboardDeadlineShipping';

/*import DashboardDeadlineList from '@/components/blocks/Dashboard/DashboardDeadlineList'*/

export default {
  name: 'DashboardDeadline',
  components: { DashbordDeadlineShipping /*DashboardDeadlineList*/ },
  mixins: [BPA],
  data() {
    return {
      token: '',
      companyList: {},
      initialData: [],
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      fullscreen: '',
      idMethodMap: new Map(),
      companyId: 1,
      VMArray: [],
      getDeadlines: false,
      loading: false,
    };
  },
  async beforeMount() {},
  mounted() {
    (this.getDeadlines = true), this.GetDeadlines();
  },
  beforeDestroy() {
    this.getDeadlines = false;
  },
  computed: {
    formatClock() {
      if (!this.initialData.now) return '';

      const date = new Date(this.initialData.now);
      const result =
        (date.getHours() - date.getTimezoneOffset() / 60).toLocaleString(
          'en-US',
          { minimumIntegerDigits: 2, useGrouping: false }
        ) +
        ':' +
        date.getMinutes().toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

      return result;
    },
  },
  watch: {
    companyId: function () {
      this.GetDeadlines();
    },
  },
  methods: {
    async GetTokenDeadlines(token) {
      this.fullscreen = 'true';
      this.loading = true;

      const base = BPA.api.base()
      const route = base + '/deadlines/dashboard_data/external'

      await fetch(
        route,
        {
          method: 'GET',
          headers: {
            'x-packship-dashboard-deadlines': token,
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          this.initialData = data;
          this.loading = false;
        })
        .catch((error) => {
          if (error == 401) {
            alert('Linket er defekt, kontakt IT for et nyt link');
          }
          console.error('there was an error!', error);
        });
    },
    async GetNonTokenDeadlines() {
      this.loading = true;
      return await new Promise((resolve, reject) => {
        BPA.api
          .GetDashboardDeadlines()
          .then((response) => {
            return BPA.api.response({ response, return: 'json' });
          })
          .then((response) => {
            if (!response.ok || !response.result) return reject();

            this.loading = false;

            resolve(response.result);
          });
      });
    },
    async GetDeadlines() {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      let callback =
        this.$route.params.token != undefined
          ? async () => {
              await this.GetTokenDeadlines(this.$route.params.token);
              this.MapMethodAndCompany(this.initialData);
              this.FormatDeadlines(this.initialData);
            }
          : async () => {
              this.initialData = await this.GetNonTokenDeadlines();
              this.MapMethodAndCompany(this.initialData);
              this.FormatDeadlines(this.initialData);
            };

      do {
        await callback();

        await delay(60000);
      } while (this.getDeadlines);
    },
    GetCurrentTime() {
      const date = new Date();
      return (
        new Date(this.initialData.now).getTime() -
        date.getTimezoneOffset() * 60000
      );
    },
    SortByCountdown(deadlineArray) {
      let tempArray = [];

      tempArray = deadlineArray;

      tempArray = tempArray.filter((deadline) => deadline.countDown > -1800000);

      tempArray = tempArray.sort((a, b) =>
        a.countDown < b.countDown ? -1 : a.countDown > b.countDown ? 1 : 0
      );

      this.VMArray = tempArray;
    },
    CountDown(nextdl) {
      const currentDate = this.GetCurrentTime();

      return nextdl - currentDate;
    },
    ToggleFullscreen() {
      this.fullscreen == 'true'
        ? (this.fullscreen = '')
        : (this.fullscreen = 'true');
      if (this.fullscreen == 'true') {
        //waiting for the input field to be rendered
        setTimeout(() => {
          this.$refs.esc.focus();
        }, 1);
      }
    },
    MapMethodAndCompany(apiData) {
      apiData.shipping_description_lookup.forEach((data) => {
        let target_res_company_id = this.idMethodMap.get(data.res_company_id);

        if (!target_res_company_id) {
          target_res_company_id = new Map();
          this.idMethodMap.set(data.res_company_id, target_res_company_id);
        }

        let target_shipping_method = target_res_company_id.get(
          data.shipping_method
        );

        if (!target_shipping_method) {
          target_shipping_method = new Map();
          target_shipping_method.set(
            data.shipping_method,
            target_shipping_method
          );
        }

        target_res_company_id.set(data.shipping_method, data);
      });
    },
    GetAgentCode(company_id, shipping_method) {
      let target_company = this.idMethodMap.get(company_id);
      if (!target_company) return null;

      let result = target_company.get(shipping_method);
      if (!result) return null;

      return result;
    },
    FormatDeadlines(apiData) {
      this.companyList = apiData.res_company_id_lookup;

      const tempDate = new Date(this.initialData.now);
      const day = this.weekdays[tempDate.getDay()];
      let VMMap = new Map();

      //sort order counts
      apiData.count_orders.forEach((order) => {
        let lookupObj = this.GetAgentCode(
          this.companyId,
          order.shipping_description
        );
        if (lookupObj !== null) {
          let target_deadline_obj = VMMap.get(lookupObj.agent_code);

          if (!target_deadline_obj) {
            VMMap.set(lookupObj.agent_code, {
              company_id: order.company_id,
              courierCompany: lookupObj.agent_code,
              orders: { new: 0, pick: 0, pack: 0 },
              countDown: 0,
              deadlines: [],
              currentTime: apiData.now,
            });
            target_deadline_obj = VMMap.get(lookupObj.agent_code);
          }
          switch (order.state) {
            case 'new':
              target_deadline_obj.orders.new += order.count;
              break;
            case 'pick':
              target_deadline_obj.orders.pick += order.count;
              break;
            case 'pack':
              target_deadline_obj.orders.pack += order.count;
              break;
          }
        }
      });
      let todaysDeadlines = apiData.deadlines_conf.filter(
        (deadline) =>
          deadline.days_mask.some((deadlineDay) => deadlineDay === day) &&
          deadline.res_company_id == this.companyId
      );

      todaysDeadlines.forEach((todayDeadline) => {
        let lookupObj = this.GetAgentCode(
          todayDeadline.res_company_id,
          todayDeadline.shipping_description
        );

        if (lookupObj) {
          let target_deadline_obj = VMMap.get(lookupObj.agent_code);

          target_deadline_obj.deadlines.push({
            hour: todayDeadline.deadline_hour,
            minute: todayDeadline.deadline_minute,
          });
        }
      });

      let deadlinesArray = [];

      Array.from(VMMap.values()).forEach((x) => {
        deadlinesArray.push(x);
      });

      deadlinesArray.forEach((deadline) => {
        deadline.deadlines = this.DeadlinesToMs(deadline.deadlines);
      });

      deadlinesArray.forEach((deadline) => {
        deadline.nextDeadline = this.FindNextDeadline(deadline.deadlines);
        deadline.countDown = this.CountDown(deadline.nextDeadline);
      });

      this.VMArray = deadlinesArray;
      this.SortByCountdown(this.VMArray);
    },
    FindNextDeadline(deadlinesInMs) {
      const currentDate = this.GetCurrentTime();

      deadlinesInMs.sort((a, b) =>
        a.miliseconds > b.miliseconds
          ? 1
          : a.miliseconds < b.miliseconds
          ? -1
          : 0
      );

      let nextDeadline = 0;
      //find next deadline and sets showdeadline accordingly
      deadlinesInMs.every((dl) => {
        if (currentDate < dl.miliseconds) {
          deadlinesInMs.nextDeadlineHour = dl.hour;
          deadlinesInMs.nextDeadlineMinute = dl.minute;
          nextDeadline = dl.miliseconds;
          return false;
        }
        return true;
      });

      return nextDeadline;
    },
    DeadlinesToMs(deadlines) {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      let deadlinesInMS = [];

      //Taking into account 0(int) saved in DB, sets MS to deadline, and avoid mutating prop
      deadlinesInMS = deadlines.map((dl) => {
        let hour = dl.hour.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
        let minute = dl.minute.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });

        today = yyyy + '-' + mm + '-' + dd + 'T' + hour + ':' + minute;

        const deadlineInMS = new Date(today).getTime();
        return {
          ...dl,
          miliseconds: deadlineInMS,
        };
      });

      let result = [
        ...new Map(deadlinesInMS.map((v) => [v.miliseconds, v])).values(),
      ];

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
//Need all css for token route
.fullscreenButton {
  background-image: url('../../../assets/images/icons/fullscreen.svg');
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &.true {
    height: 30px;
    width: 30px;
    overflow: visible;
    z-index: 101;
    margin-top: 40%;
    margin-right: 2%;
    margin-bottom: 2%;
    position: fixed;
    bottom: 00;
    right: 00;
    background-image: url('../../../assets/images/icons/fullscreen-exit.svg');
  }
}
.view {
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 0px) and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @media screen and (min-width: 1280px) and (max-width: 1920px) {
  }
  padding-top: 25px;
  padding-bottom: 25px;
}
.dashviewcol {
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  background-color: #ffffff;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  box-sizing: border-box;
}
.total-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
  position: relative;

  &__logo {
    width: 60%;
  }

  &.true {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    z-index: 100;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px;
    background-color: white;
  }
  &__item {
    width: calc(20% - 10px);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #eaeaea;
    padding-bottom: 10px;

    &:nth-last-of-type(1) {
      border: none;
    }
  }
}
.dashboard-actions {
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;

  &.true {
    margin-right: 30px;
    margin-left: 30px;
  }
  label {
    display: inline-flex;
    flex-direction: column;
    position: relative;

    span {
      position: absolute;
      transform: translateY(-100%);
    }

    input {
      height: 35px;
      width: 100%;
      border: none;
      color: #606060;
      border-bottom: 2px solid #eaeaea;
      padding-left: 15px;
      box-sizing: border-box;
      outline: none;
      font-size: 1.111rem;
      border-radius: 10px;

      &::placeholder {
        font-size: 1.111rem;
        color: #606060;
      }

      &.file {
        border: none;
      }

      &.number::-webkit-outer-spin-button,
      &.number::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.number[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
}
.companyDropDown {
  height: 35px;
  width: 20%;
  color: #606060;
  border: none;
  border-bottom: 2px solid #eaeaea;
  padding-left: 15px;
  box-sizing: border-box;
  outline: none;
  font-size: 1.111rem;
}
.clock {
  font-size: 1.222rem;
  &.true {
    font-size: 2.478rem;
  }
}
.deadlinesWrapper {
  display: flex;
  flex-direction: row;
}
.fullscreen {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
